/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";

import { useSession } from "@supabase/auth-helpers-react";
import { useLocation } from "react-router-dom";
import FloatingAlert from "../alert/alert";
import NavButton from "../buttons/NavigationButton";
import { adminButtons, navButtons } from "../layout/navButtons";
import { useUserContext } from "../user/userContext";
import DashboardHeader from "./dashboardHeader";
import { notProtectedSlugs } from "../auth/authContext";
import { plentiDigiphyLogo } from "./LayoutBase";

const LayoutAccount = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [isHidden, setIsHidden] = useState(false);
  const session = useSession();
  const { user } = useUserContext();
  const [navItems, setNavItems] = useState<any[]>([]);

  useEffect(() => {
    if (user && user?.type === "admin") {
      const list = [...navButtons];
      list.splice(list.length - 1, 0, ...adminButtons);
      setNavItems(list);
    }
    if (user && user?.type === "standard") {
      setNavItems(navButtons);
    }
  }, [user]);

  const [screenWidth, setScreenWidth] = useState(0);

  // Add a listener for window resize events
  useEffect(() => {
    if (window) {
      if (!screenWidth) {
        setScreenWidth(window.innerWidth);
      }

      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };

      // Attach the event listener
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [screenWidth]);

  if (
    !session ||
    notProtectedSlugs.some((item) => item === location?.pathname)
  ) {
    return <div>{children}</div>;
  }

  return (
    <div className="mb-8 flex w-full flex-row items-start ">
      {!isHidden && (
        <div
          className={
            screenWidth < 768
              ? "absolute left-0 top-0 z-10 h-full min-w-[60px] shrink-0 border-r-4 bg-white sm:w-1/5 md:sticky md:top-4 md:h-screen"
              : "block w-2/5 min-w-[60px] shrink-0 border-r-4 sm:w-1/5 md:sticky md:top-4 md:h-screen"
          }
        >
          <div className="overflow-hidden md:h-screen flex flex-col justify-between">
            <div className="flex flex-col gap-1">
              {navItems.map((item, index) => {
                if (!item.path && item.path !== "/") {
                  return (
                    <Typography
                      variant="small"
                      className="ml-4 mt-2 mb-1 font-bold"
                      key={index}
                    >
                      {item.label}
                    </Typography>
                  );
                } else {
                  return (
                    <NavButton
                      key={index}
                      path={item.path}
                      icon={item.icon}
                      label={item.label}
                    />
                  );
                }
              })}
            </div>
            <div className="flex justify-center items-end mb-10">
              <img
                height={100}
                width={100}
                src={plentiDigiphyLogo}
                alt="hero"
              />
            </div>
          </div>
        </div>
      )}

      <div className="w-screen">
        <DashboardHeader setIsHidden={setIsHidden} isHidden={isHidden} />
        {children}
      </div>
      <FloatingAlert />
    </div>
  );
};

export default LayoutAccount;
