import { Link } from "react-router-dom";

export const plentiDigiphyLogo =
  "https://sdknkezpybxgthpealqu.supabase.co/storage/v1/object/public/logos/digiphy-x-plenti-logo-lockup.png";

const LayoutBase = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="landing-cover relative flex flex-col items-center justify-start h-screen">
      <div className="bg-slate-50 bg-transparent bg-cover w-full fixed top-0 left-0 right-0">
        <Link
          to="/"
          className="mt-[15vh] md:mt-[5vh] w-full flex justify-center md:justify-start pl-4 md:pl-8 items-center"
        >
          <img
            src={plentiDigiphyLogo}
            alt="logo"
            className={"w-[130px] h-[60px]"}
          />
        </Link>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        {children}
      </div>
    </div>
  );
};

export default LayoutBase;
