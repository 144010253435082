/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Button, Typography } from "@material-tailwind/react";
import { Divider } from "@tremor/react";
import { useNavigate } from "react-router-dom";
import PlentiFormLabel from "../forms/PlentiFormLabel";
import { CopyToClipboardIconButton } from "../hooks/copyToClipboard";
import LearnMoreButton from "../link/LearnMoreButton";
import { LinkToId } from "../table/linkToId";
import { useCompanyContext } from "./companyContext";

function IntegrationsContainer() {
  const navigate = useNavigate();

  const { selectedCompanyConfig, generateJwt } = useCompanyContext();

  return (
    <div className="max-w-2xl flex flex-col gap-10">
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Digiphy</Typography>
        </div>
        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex flex-row justify-between gap-10 w-fit word-wrap ">
            {selectedCompanyConfig?.jwt ? (
              <div>
                <PlentiFormLabel>Token</PlentiFormLabel>
                <div className="flex flex-row gap-2 items-center">
                  <p
                    className={"text-slate-700 break-words max-w-xl flex-wrap"}
                  >
                    {selectedCompanyConfig?.jwt}
                  </p>
                  <CopyToClipboardIconButton
                    text={selectedCompanyConfig?.jwt || ""}
                  />
                </div>
              </div>
            ) : (
              <Button
                onClick={() =>
                  generateJwt({
                    companyId: selectedCompanyConfig?.company_id as string,
                  })
                }
                variant="outlined"
                color="gray"
                size="sm"
              >
                Generate Token
              </Button>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Shopify</Typography>
          <Button
            className="flex items-center gap-2 p-1"
            onClick={() => {
              navigate("/integrations/shopify");
            }}
            variant="text"
            color="gray"
            size="sm"
          >
            Details
          </Button>
        </div>
        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex flex-row justify-between">
            <LinkToId
              type="Link to Plenti's Shopify app"
              id={"https://apps.shopify.com/plenti-ai-1"}
              baseUrl={""}
            />
            <LearnMoreButton
              variant="text"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=3bulq8Xlx5s",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <Typography variant="h6">Email</Typography>
          <Button
            className="flex items-center gap-2 p-1"
            onClick={() => {
              navigate("/integrations/email");
            }}
            variant="text"
            color="gray"
            size="sm"
          >
            Details
          </Button>
        </div>
        <Divider className="my-1" />
        <div className="my-4 grid w-full gap-10">
          <div className="flex flex-row justify-end">
            <LearnMoreButton
              variant="text"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=AZrbrnbqjM4",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegrationsContainer;
