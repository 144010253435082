/* eslint-disable react-hooks/exhaustive-deps */
import { Card, LineChart, Metric, Text, Title } from "@tremor/react";
import { Fragment, useEffect, useState } from "react";

import {
  countTotalForCampaign,
  flattenLeadCampaignCounts,
} from "./analyticsHelpers";
import { useLeadAnalyticsContext } from "./leadAnalyticsContext";
import { useSetCampaignAndDate } from "./setCampaignAndDate/useSetCampaignAndDate";

function LeadsChart() {
  const { date, selectedCampaign } = useSetCampaignAndDate();
  const startDate = date?.from || null;
  const endDate = date?.to || null;
  const { dailyLeadCampaignCountsByCampaign } = useLeadAnalyticsContext();

  const [flattenedLeadCampaignCounts, setFlattenedLeadCampaignCounts] =
    // @ts-ignore
    useState<Array<{ day: string; [campaignName: string]: number }>>();

  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (dailyLeadCampaignCountsByCampaign) {
      const flattened = flattenLeadCampaignCounts(
        dailyLeadCampaignCountsByCampaign,
        startDate,
        endDate,
        selectedCampaign?.name
      );

      setFlattenedLeadCampaignCounts(flattened);
      // @ts-ignore
      setCount(countTotalForCampaign(flattened, selectedCampaign?.name));
    }
  }, [dailyLeadCampaignCountsByCampaign, startDate, endDate, selectedCampaign]);

  const dataFormatter = (number: number) =>
    `${Intl.NumberFormat("us").format(number).toString()}`;

  return (
    <Fragment>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <Card className=" max-w-xs">
          <Text>Total Lead Campaigns</Text>
          <Metric>{count}</Metric>
        </Card>
      </div>
      {flattenedLeadCampaignCounts && flattenedLeadCampaignCounts.length > 0 ? (
        <Card>
          <Title>Leads added by day</Title>

          <LineChart
            className="mt-6"
            data={flattenedLeadCampaignCounts}
            index="day"
            categories={[
              selectedCampaign?.name ? selectedCampaign?.name : "Total",
            ]}
            valueFormatter={dataFormatter}
            colors={["cyan", "blue", "purple", "green", "red"]}
            yAxisWidth={40}
          />
        </Card>
      ) : (
        <p>
          No leads yet. Once leads are added to a campaign they will appear
          here.
        </p>
      )}
    </Fragment>
  );
}

export default LeadsChart;
