/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AddLocationDialogBody from "../location/addLocationModalBody";
import { useLocationContext } from "../location/locationContext";
import LocationTableList from "../location/locationTableList";
import { handlePageNav } from "../table/handlePagination";
import TableWithSearch, { TableHeadItem } from "../table/tableWithSearch";

import { LocationRow } from "../../api/location.type";
import GenericDialog from "../dialog/genericDialog";

const LocationList: React.FC = () => {
  const TABLE_HEAD = [
    { label: "Name" },
    { label: "Chain" },
    { label: "Address/City" },
    { label: "State" },
    { label: "Zip" },
    { label: "Phone" },
    { label: "Active" },
  ];

  const [tableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  const { locations } = useLocationContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState("userMessage");

  function baseFilter() {
    return (location: LocationRow, index: number) => index < 10;
  }

  const [locationFilterFunction, setLocationFilterFunction] =
    useState<(location: LocationRow, index: number) => boolean>(baseFilter);

  function handleTabChange(value: string) {
    if (value === tabValue) {
      setTabValue("");
    } else {
      setTabValue(value);
    }
  }

  const [open, setOpen] = useState(false);

  // function BulkUpload() {
  //   return (
  //     <div>
  //       <Button
  //         color="deep-purple"
  //         className="flex items-center gap-3"
  //         onClick={() => navigate("/locations/bulk-upload")}
  //       >
  //         <UploadMany className="h-4 w-4" /> Bulk Upload
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <div className="p-5">
      <GenericDialog title="Add Location" open={open} setOpen={setOpen}>
        <AddLocationDialogBody setOpen={setOpen} />
      </GenericDialog>
      {locations && (
        <TableWithSearch
          tableHead={tableHead}
          type="location"
          rowCount={
            locations && locations.length > 0 ? locations?.length : null
          }
          handleAddClick={() => {
            setOpen(!open);
          }}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setLocationFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setLocationFilterFunction,
              advancePage: false,
            })
          }
          handleTabChange={handleTabChange}
          tabValue={tabValue}
        >
          <LocationTableList
            locations={locations}
            filterFunction={locationFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
};

export default LocationList;
